let $toggleMenu = document.getElementsByClassName("js-toggleMenu");
let $toggleSubMenu = document.getElementsByClassName("js-toggleSubMenu");

if ($toggleMenu.length) {
    $toggleMenu[0].addEventListener("click", function (e) {
        let $menu = document.getElementById(this.getAttribute("data-toggle"));
        let _self = this;

        this.classList.toggle("is-on");
        $menu.classList.toggle("is-on");
        document.body.classList.toggle("is-menuon");

        $menu.addEventListener("click", function (e) {
            _self.classList.remove("is-on");
            this.classList.remove("is-on");
            document.body.classList.remove("is-menuon");
        });

        let $links = $menu.getElementsByTagName("a");

        for (let i = 0; i < $links.length; i++) {
            $links[i].addEventListener("click", function (e) {
                _self.classList.remove("is-on");
                $menu.classList.remove("is-on");
                document.body.classList.remove("is-menuon");
            });
        }

        $menu.childNodes[0].addEventListener("click", function (e) {
            e.stopPropagation();
        });
    });

    for (let i = 0; i < $toggleSubMenu.length; i++) {
        $toggleSubMenu[i].addEventListener("click", function (e) {
            this.classList.toggle("is-on");
        });
    }
}
