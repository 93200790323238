require("./menu");

document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll("#zone-footer .col-title").forEach(colTitle => {
        colTitle.addEventListener('click', function () {
            this.classList.toggle("active");
        })
    });
});

if (window.jQuery) {
// jQuery plugin to prevent double submission of forms
    jQuery.fn.preventDoubleSubmission = function () {
        $(this).on('submit', function (e) {
            let $form = $(this);
            if ($form.data('submitted') === true) {
                // Previously submitted - don't submit again
                e.preventDefault();
            } else {
                // Mark it so that the next submit can be ignored
                $form.data('submitted', true);
            }
        });
        // Keep chainability
        return this;
    };
}